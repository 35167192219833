/*
 * Site Navigation
 */
(function($) {

    //Mobile Hamburger Button
    $('.header-container .navbar-toggler').on('click', function() {
        $(this).toggleClass('open');
        $(this).closest('.site-header').find('.drop-menu').toggleClass('open');
        $(this).closest('.site-header').find('.header-main').toggleClass('open')
    });

    $('.navbar-toggler').click(function() {
        //add both classes in toggleClass
        $('body').toggleClass('navbar-open');
    })

    
})(jQuery);
